<template>
    
    <v-row>
        <v-btn 
            class="mb-6 mr-6"
            fixed
            color="primary"        
            large            
            bottom
            right                                    
            fab       
            @click="saveAll"   
            :loading="loading_saveAll"           
        >                            
            <v-icon color="white">mdi-content-save</v-icon>
        </v-btn>  

        <v-col cols="12" lg="3">
            <base-card>
                <v-card-text>

                    <div class="d-flex align-center justify-center mb-6">
                        <!--:file-extns="['.pdf', '.csv', '.docx', '.xlsx']"-->
                        <FileUploaderPhoto
                            :profilePicThumbnailUrl="profilePicThumbnailUrl"
                            :multiple="false"
                            height="60vh"
                            @files="handleFiles"
                        />
                    </div>

                    
                    <div class="d-flex align-center justify-center mb-6">                            
                        <div class="text-center">
                            <!--<v-img
                                height="128"
                                width="128"
                                src="@/assets/images/faces/2.jpg  https://placehold.co/200x200?text=Upload+Photo"
                                class=" rounded-circle"
                                alt=""
                            ></v-img>-->
                            <!--<v-img
                                height="128"
                                width="128"
                                :src="profilePicThumbnailUrl"
                                class=" rounded-circle"
                                alt=""
                                style="margin: auto;"
                            ></v-img>                            
                            <div style="font-size: 0.8em">
                                Click image to change
                            </div>-->
                            <h5 class="font-weight-bold my-3">
                                {{ firstname }} {{ lastname }}
                            </h5>
                        </div>
                    </div>
                    <v-divider class="my-4" />
                    <div v-if="mainrole!=='Customer'" class="d-flex justify-space-between itesm-center mt-6">
                        <h5  class="ma-0">
                            {{ mainrole }}
                        </h5>
                        <v-btn color="primary" class="rounded-pill" @click="previewMyProfile">
                            Preview Profile
                        </v-btn>
                    </div> 
                    <!--<div v-if="mainrole!=='Customer'" class="d-flex justify-space-between itesm-center mt-2">
                        <v-btn color="primary" class="rounded-pill" depressed :to="'/mng/profiledetails'" style="margin-left: auto; margin-right: auto;">
                            Preview Profile
                        </v-btn>
                    </div>-->
                    <v-divider v-if="mainrole!=='Customer'" class="my-4" />
                    <div v-if="mainrole!=='Customer'" class="d-flex- justify-space-between itesm-center mt-6">
                        <v-select         
                            placeholder=""                                                            
                            v-model="help_visibility"
                            :items="['Hidden','Public']"                 
                            label="Profile Visibility" 
                            
                            :rules="[]"     
                            required                                                         
                            :clearable="true"                      
                        /> 
                        <br/>
                        <v-alert v-if="mainrole!=='Customer'" 
                        border="right"
                        colored-border
                        type="error"
                        elevation="2"
                        style="pointer: cursor;"
                        @click="()=>{}"
                    >
                        Setup your availability before you can start accepting bookings.
                    </v-alert>
                        <!-- <v-alert v-if="mainrole!=='Customer'" dense type="info">
                            Click <strong>here</strong> to setup your availability before you can start accepting bookings.
                        </v-alert> -->
                        <v-switch
                            v-if="mainrole!=='Customer'"
                            :v-model="available_for_bookings"
                            label="Can Accept Bookings"

                        ></v-switch>                        
                        <!-- <h5 class="ma-0">
                            Therapist
                        </h5>
                        <v-btn color="primary" class="rounded-pill" depressed :to="'/mng/profiledetails'">
                            Preview Profile
                        </v-btn> -->
                    </div> 
                    <v-divider v-if="mainrole!=='Customer'"  class="my-4" />
                    <div class="d-flex align-center justify-space-between mb-2">
                        <p class="mt-2 mb-0 flex-1">
                            Completed Sessions
                        </p>
                        <div class="flex-1 text-right">
                            <v-chip small class="ma-2">
                                {{ completed_sessions_count }}
                            </v-chip>
                        </div>
                    </div>
                    <div v-if="mainrole!=='Customer'" class="d-flex align-center justify-space-between mb-2">
                        <p class="mt-2 mb-0 flex-1">
                            Earned in June
                        </p>
                        <div class="flex-1 text-right">
                            <v-chip small class="ma-2">
                                {{ total_earnings }} €
                            </v-chip>
                        </div>
                    </div>   
                    <div v-if="mainrole!=='Customer'" class="d-flex align-center justify-space-between mb-2">
                        <p class="mt-2 mb-0 flex-1">
                            Profile Visitors (Last 30 Days)
                        </p>
                        <div class="flex-1 text-right">
                            <v-chip small class="ma-2">
                                {{ profile_visitors_count }}
                            </v-chip>
                        </div>
                    </div> 
                    
                                 
                </v-card-text>
            </base-card>
        </v-col>
        <v-col cols="12" lg="9">
            <!--<div class="d-flex align-center">
                <p class="font-weight-medium mr-3 mb-3">
                    Setup your profile
                </p>
                <v-divider />                
            </div>-->

            <!-- <base-card class="mb-4" v-if="help_professionalquestion">
                <v-card-text class="">
                    <div class="d-flex align-center justify-space-between">
                        <div>
                            <div class="card-title">
                                Are you a professional?
                            </div>
                            <p class=" font-weight-medium">
                                Let us know if you are a professional, so that you can setup your account and start getting clients!
                            </p>
                            <p>
                                <v-btn class="text-capitalize" outlined color="primary" :to="'/mng/professionalsetup'">
                                    Yes, I am a professional
                                </v-btn>
                            </p>
                            <p>
                                <v-btn icon class="text-capitalize" outlined color="error" @click="()=> { help_professionalquestion=false }">
                                    No, I am not a professional
                                </v-btn>
                                <span class="ml-10">(You will be able to switch to a professional profile later, if you want.)</span>
                            </p>
                        </div>
                        <div>
                            <v-img
                                width="128"
                                height="128"
                                class="d-sm-none d-md-block"
                                src="@/assets/images/illustrations/business_deal.svg"
                                alt=""
                            ></v-img>
                        </div>
                    </div>
                </v-card-text>
            </base-card> -->

            <base-card class="mb-4">
                <v-card-text>
                     
                    <v-tabs class="mt-4">
                        <v-tab class="text-capitalize">
                            {{ $t("profile.user info") }}
                        </v-tab>
                        <v-tab v-if="mainrole==='Therapist'" class="text-capitalize">
                            {{ $t("profile.therapist profile") }}
                        </v-tab>                        
                        <v-tab v-if="mainrole==='Therapist'" class="text-capitalize">
                            {{ $t("profile.availability") }}
                        </v-tab>
                        <v-tab v-if="mainrole==='Therapist'" class="text-capitalize">
                            {{ $t("profile.servicescatalog") }}
                        </v-tab>
                        <v-tab v-if="mainrole==='Business Owner'" class="text-capitalize">
                            {{ $t("profile.business profile") }}
                        </v-tab>                        
                        <v-tab-item transition="true">
                            <v-row class="pb-0 mb-0 pt-4">
                                <v-col cols="12" class="pb-0 mb-0">
                                    <h6 class="mb-0 ml-4">{{ $t("profile.basic info") }}</h6>                                    
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="4" class="pt-0">
                                    <v-list>
                                        <v-list-item>
                                            <v-list-item-content class="pb-0">
                                                <!--disabled="true"-->
                                                <v-text-field
                                                    readonly
                                                    label="E-mail"
                                                    v-model="email"                                                                                                     
                                                >
                                                    <template v-slot:prepend>
                                                        <v-icon>
                                                            mdi-lock
                                                        </v-icon>
                                                    </template>   
                                                </v-text-field>
                                                <v-btn                                                     
                                                    depressed
                                                    color="primary"       
                                                    small                                                                                                                     
                                                >
                                                    <v-icon left>
                                                        mdi-account-edit
                                                    </v-icon>
                                                    {{ $t("profile.change e-mail") }}
                                                </v-btn> 
                                                <!--<v-btn class="text-capitalize"                                                                                     
                                                    color="primary"                                 
                                                    dark                                                                    
                                                    @click="()=>{}"  
                                                >
                                                    <v-icon left>mdi-plus-outlined</v-icon>
                                                    + Add A Location
                                                </v-btn>--> 
                                            </v-list-item-content>                                            
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content class="pb-0">
                                                <!--disabled="true"-->
                                                <v-text-field
                                                    readonly
                                                    label="Mobile No"
                                                    v-model="mobile"                                                    
                                                >
                                                    <template v-slot:prepend>
                                                        <v-icon>
                                                            mdi-lock
                                                        </v-icon>
                                                    </template> 
                                                </v-text-field>                                                
                                                <v-btn                                                     
                                                    depressed
                                                    color="primary"       
                                                    small                                                                                                                     
                                                >
                                                    <v-icon left>
                                                        mdi-account-edit
                                                    </v-icon>
                                                    {{ $t("profile.change mobile") }}
                                                </v-btn> 
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0">
                                    <v-list>                                        
                                        <v-list-item>
                                            <v-list-item-content class="pb-0">
                                                <v-text-field
                                                    label="Firstname"
                                                    v-model="firstname"
                                                    :error-messages="firstnameValState"
                                                />
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content class="pb-0">
                                                <v-text-field
                                                    label="Lastname"
                                                    v-model="lastname"
                                                    :error-messages="lastnameValState"
                                                />
                                            </v-list-item-content>                                            
                                        </v-list-item>                                        
                                    </v-list>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0">
                                    <v-list>                                        
                                        <v-list-item>
                                            <v-list-item-content class="pb-0">
                                                <v-select         
                                                    placeholder=""                                                            
                                                    v-model="language"
                                                    :items="languagesOptions"                 
                                                    label="Language" 

                                                    :rules="[]"     
                                                    required                                                         
                                                    :clearable="true"                      
                                                /> 
                                            </v-list-item-content>
                                        </v-list-item>                                       
                                    </v-list>
                                </v-col>
                            </v-row>
                            <v-row class="pb-0 mb-0 pt-4">
                                <v-col cols="12" class="pb-0 mb-0">
                                    <h6 class="mb-0 ml-4">{{ $t("profile.profile info") }}</h6>                                    
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="4" class="pt-0">
                                    <v-list>
                                        <v-list-item>
                                            <v-list-item-content class="pb-0">
                                                <v-select         
                                                    placeholder=""                                                            
                                                    v-model="gender"
                                                    :items="genderOptions"                 
                                                    label="Genetic Gender" 
                                                    :error-messages="genderValState"
                                                    :rules="[]"     
                                                    required                                                         
                                                    :clearable="true"                      
                                                />
                                            </v-list-item-content>                                            
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content class="pb-0">
                                                <v-select         
                                                    placeholder=""                                                            
                                                    v-model="age"
                                                    :items="ageOptions"                 
                                                    label="Age"                                                                                                                                                          
                                                    :clearable="true"                      
                                                />
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>                                
                            </v-row>
                            <v-row class="pb-0 mb-0 pt-4">
                                <v-col cols="12" class="pb-4 mb-4">
                                    <h6 class="mb-0 ml-4">{{ $t("profile.my locations") }}</h6>                                    
                                    <span class="mb-0 ml-4">{{ $t("profile.choose appointment locations") }}</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="pt-0 mt-4">
                                    <template v-for="(contact, index) in customerLocations">
                                        <div :key="'cont_'+index">
                                            <LocationEditor :key="'cont_'+index" :index="index" :loading_setDefaultLocation="loading_setDefaultLocation" v-model="customerLocations[index]" @set-default="setDefaultLocation"/>
                                        </div>
                                    </template>         
                                    <v-list>                                   
                                    <v-list-item>
                                        <v-list-item-content class="pb-0">                                            
                                            <v-btn class="text-capitalize"                                 
                                                block 
                                                color="primary"                                 
                                                dark                
                                                :loading="false"  
                                                x-large
                                                @click="customerLocations.push(createContactObject())"                                            
                                            >
                                                <v-icon left>mdi-plus-outlined</v-icon>
                                                + {{ $t("profile.add a location") }}
                                            </v-btn>   
                                            
                                        </v-list-item-content>
                                    </v-list-item>                                   
                                </v-list>                            
                                </v-col>                                
                            </v-row>

                        </v-tab-item>
                        <v-tab-item v-if="mainrole==='Therapist'">
                            <!-- <pre>
                                {{ JSON.stringify(therapist_svc_fieldgroup, null, 2) }}
                            </pre> -->
                            <h3 class="ml-3 mt-3">Professional Information</h3>
                            
                            <v-list>
                                <v-list-item>
                                    <v-list-item-content class="pb-0">
                                        <v-text-field 
                                            label="Short description"       
                                            placeholder="A therapist with experience in athletes."                                      
                                            v-model="short_description"    
                                            :rules="[]"                                                
                                            class="col-6 p-0 pr-4"
                                            style="padding: 0px 0px;"
                                        />                                         
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content class="pb-0">
                                        <v-textarea
                                            label="Long Description" 
                                            placeholder="I've worked with professional athletes for more than 5 years."
                                            v-model="long_description"    
                                            :rules="[]"
                                            :validate-on-blur="false"
                                            class="col-6 p-0 pr-4"
                                            style="padding-inline: 0px;"  
                                            mu
                                        />                                                                              
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
        
                            <TherapistServicesAndExperienceFieldGroup v-model="therapist_svc_fieldgroup" :occupations="occupations" />
                            <h3 class="ml-3 mt-3">Booking</h3>
                            <TherapistBookingFieldGroup v-model="therapist_booking_fieldgroup" />
                            <!--<h3 class="ml-3">Location</h3>
                            <TherapistLocationSelector v-model="therapist_location_fieldgroup" :prefectures="prefectures" :cities="cities" />-->


                            <!--<v-row>
                                <v-col cols="12" md="12">
                                    <base-card>
                                        <v-card-title>
                                            <div class="card-title">Additional Photos</div><span>
                                                <v-btn class="text-capitalize"                                                                                     
                                                    color="primary"                                 
                                                    dark             
                                                    small   
                                                    style="margin-top: -20px; margin-left: 20px;"
                                                                                                                                     
                                                >                                                    
                                                    + Upload
                                                </v-btn>
                                            </span>
                                        </v-card-title>
                                        <v-card-text>
                                            <vueper-slides
                                                class="no-shadow"
                                                :visible-slides="4"
                                                slide-multiple
                                                fixed-height="350px"
                                                :gap="2"
                                                :slide-ratio="1 / 2"
                                                :dragging-distance="200"
                                                :breakpoints="{
                                                    800: {visibleSlides: 2, slideMultiple: 2},
                                                    400: {visibleSlides: 1}
                                                }"
                                            >                                                
                                                <vueper-slide
                                                    v-for="(card, index) in extra_images"
                                                    :key="index"
                                                >
                                                    <template v-slot:content>
                                                        <base-card class="h-full">
                                                            <div class="">
                                                                <img
                                                                    class="md:w-6/12"
                                                                    :src="card"
                                                                />
                                                            </div>
                                                            <v-btn class="text-capitalize"                                                                                     
                                                                color="gray"                                 
                                                                outlined     
                                                                small   
                                                                style="margin-left: 20px;"                                                                                                                                                
                                                            >                                                    
                                                                X Remove
                                                            </v-btn>
                                                        </base-card>
                                                    </template>
                                                </vueper-slide>
                                            </vueper-slides>
                                        </v-card-text>
                                    </base-card>
                                </v-col>
                            </v-row>-->


                            
                        </v-tab-item>
                        <v-tab-item v-if="mainrole==='Therapist'">
                            <v-row>
                                <v-col cols="12" md="6">
                                    <h3 class="ml-3 mt-3">Weekly Schedule
                                        <!--<v-btn class="text-capitalize"                                                                                     
                                        color="primary"                                 
                                        dark             
                                        small   
                                        style="margin-left: 20px;"
                                                                                                                            
                                    >                                                    
                                        + Create Another Schedule
                                    </v-btn>-->
                                    </h3>                                                                         
                                    <WeekdayAvailabilityEditor
                                        weekday="Monday"
                                        v-model="availability_monday"
                                    >                                    
                                    </WeekdayAvailabilityEditor>
                                    <WeekdayAvailabilityEditor
                                        weekday="Tuesday"
                                        v-model="availability_tuesday"
                                    ></WeekdayAvailabilityEditor>
                                    <WeekdayAvailabilityEditor
                                        weekday="Wednesday"
                                        v-model="availability_wednesday"
                                    ></WeekdayAvailabilityEditor>
                                    <WeekdayAvailabilityEditor
                                        weekday="Thursday"
                                        v-model="availability_thursday"
                                    ></WeekdayAvailabilityEditor>
                                    <WeekdayAvailabilityEditor
                                        weekday="Friday"
                                        v-model="availability_friday"
                                    ></WeekdayAvailabilityEditor>
                                    <WeekdayAvailabilityEditor
                                        weekday="Saturday"
                                        v-model="availability_saturday"
                                    ></WeekdayAvailabilityEditor>
                                    <WeekdayAvailabilityEditor
                                        weekday="Sunday"
                                        v-model="availability_sunday"
                                    ></WeekdayAvailabilityEditor>
                                    <h3 class="ml-3 mt-3">Schedule By Date</h3>
                                    <DateAvailabilityEditor
                                        weekday="Custom Date"
                                        v-model="availability_customdate"
                                    ></DateAvailabilityEditor>                                                                     
                                </v-col>
                                <v-col cols="12" md="6">
                                    <h3 class="ml-3 mt-3">Area Coverage</h3>
                                    <AreaCoverageEditor></AreaCoverageEditor>
                                    <v-btn class="text-capitalize"                                 
                                        block 
                                        color="primary"                                 
                                        dark                
                                        :loading="false"  
                                        x-large
                                        @click="snackbar=true"                                            
                                    >
                                        <v-icon left>mdi-plus-outlined</v-icon>
                                        + {{ $t("profile.add an area") }}
                                    </v-btn>   
                                </v-col>
                            </v-row>                                                        
                        </v-tab-item>
                        <v-tab-item v-if="mainrole==='Therapist'">
                            <v-row>
                                <v-col cols="4">                                    
                                    <div style="width: 100%; min-height: 500px; max-height: 500px; display: flex; flex-direction: row; font-size: 0.5em;">
                                        <div style="width: 40%; min-height: 500px;">
                                            <vue-perfect-scrollbar
                                                :settings="{ suppressScrollX: true, wheelPropagation: false }"
                                                class="h-100 rtl-ps-none ps scroll"
                                                style="height: 100%"
                                            >
                                                <v-list dense>
                                                    <v-subheader>Κατηγορίες</v-subheader>
                                                    <v-list-item-group>
                                                        <v-list-item @click="selectedCategory=category.value" v-for="category in columnSelector_Categories" :key="category.value">
                                                            <v-list-item-content><v-list-item-title>{{ category.text }}</v-list-item-title></v-list-item-content>
                                                            <v-list-item-icon v-if="selectedCategory == category.value"><v-icon>mdi-chevron-right</v-icon></v-list-item-icon>
                                                        </v-list-item>                                                                    
                                                    </v-list-item-group>
                                                </v-list>
                                            </vue-perfect-scrollbar>                                                            
                                        </div>
                                        <div style="width: 60%; min-height: 500px; max-height: 500px; "> 
                                            <vue-perfect-scrollbar
                                                :settings="{ suppressScrollX: true, wheelPropagation: false }"
                                                class="h-100 rtl-ps-none ps scroll"
                                                style="height: 100%"
                                            >
                                                <v-list dense>
                                                    <v-subheader>Υπηρεσίες</v-subheader>
                                                    <v-list-item-group>
                                                        <v-list-item @click="addServiceFilter(service.value)" v-for="service in columnSelector_Services" :key="service.value">
                                                            <v-list-item-content><v-list-item-title>{{ service.text }}</v-list-item-title></v-list-item-content>
                                                            <v-list-item-icon><v-icon>mdi-chevron-right</v-icon></v-list-item-icon>
                                                        </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                            </vue-perfect-scrollbar>                                                                     
                                        </div>
                                    </div>
                                </v-col>
                                <v-col cols="8">                                    
                                    <service-catalog-datatable-editor ref="serviceCatalog" :itemslist="myservices" :ref_services="dropdown_service_types" @create="therapServiceCreate" @update="therapServiceUpdate" @delete="therapServiceDelete"></service-catalog-datatable-editor>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item v-if="mainrole==='Business Owner'">
                            <!-- <pre>
                                {{ JSON.stringify(businessowner_businessinfo_fieldgroup, null, 2) }}
                            </pre>                                                     -->
                            <BusinessOwnerBusinessInfoFieldGroup v-model="businessowner_businessinfo_fieldgroup" />  
                            <v-row class="pb-0 mb-0 pt-4">
                                <v-col cols="12" class="pb-4 mb-0">
                                    <h6 class="mb-0 ml-4">Locations</h6>   
                                    <span class="mb-0 ml-4">Choose the location(s) and contact information of your business</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="pt-0">
                                    <template v-for="(contact, index) in locations">
                                        <div :key="'cont_'+index">
                                            <LocationEditor :key="'cont_'+index" :index="index" v-model="locations[index]" />
                                        </div>
                                    </template>         
                                    <v-list>                                   
                                    <v-list-item>
                                        <v-list-item-content class="pb-0">                                            
                                            <v-btn class="text-capitalize"                                 
                                                block 
                                                color="primary"                                 
                                                dark                
                                                :loading="false"  
                                                @click="locations.push(createContactObject())"  
                                                x-large                                          
                                            >
                                                <v-icon left>mdi-plus-outlined</v-icon>
                                                Add Another Location
                                            </v-btn>   
                                            
                                        </v-list-item-content>
                                    </v-list-item>                                   
                                </v-list>                            
                                </v-col>                                
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <base-card>
                                        <v-card-title>
                                            <div class="card-title">Additional Photos</div><span>
                                                <v-btn class="text-capitalize"                                                                                     
                                                    color="primary"                                 
                                                    dark             
                                                    small   
                                                    style="margin-top: -20px; margin-left: 20px;"
                                                                                                                                     
                                                >                                                    
                                                    + Upload
                                                </v-btn>
                                            </span>
                                        </v-card-title>
                                        <!--<v-card-text>
                                            <vueper-slides
                                                class="no-shadow"
                                                :visible-slides="4"
                                                slide-multiple
                                                fixed-height="350px"
                                                :gap="2"
                                                :slide-ratio="1 / 2"
                                                :dragging-distance="200"
                                                :breakpoints="{
                                                    800: {visibleSlides: 2, slideMultiple: 2},
                                                    400: {visibleSlides: 1}
                                                }"
                                            >                                                
                                                <vueper-slide
                                                    v-for="(card, index) in cards"
                                                    :key="index"
                                                >
                                                    <template v-slot:content>
                                                        <base-card class="h-full-">                                                        
                                                            <div class="">
                                                                <img
                                                                    class="md:w-6/12"
                                                                    :src="card.img"
                                                                />                                                                
                                                            </div>
                                                        </base-card>
                                                        
                                                    </template>
                                                </vueper-slide>
                                            </vueper-slides>
                                        </v-card-text>-->
                                    </base-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                v-for="n in 9"
                                :key="n"
                                class="d-flex child-flex"
                                cols="4"
                                >
                                <v-img
                                    :src="`https://picsum.photos/500/300?image=${n * 5 + 10}`"
                                    :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
                                    aspect-ratio="1"
                                    class="grey lighten-2"
                                >
                                    <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                        ></v-progress-circular>
                                    </v-row>
                                    </template>
                                </v-img>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-tabs>                    
                </v-card-text>
            </base-card>

            <!-- <base-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <div class="card-title mb-0">
                                How to build your success on Market in 3 steps
                            </div>
                        </v-col>
                        <v-col
                            cols="12"
                            lg="4"
                            class="text-18-left text-center"
                        >
                            <v-icon class="primary--text mb-2"
                                >mdi-microphone-outline</v-icon
                            >
                            <p class="heading-label ma-0 ">Get Noticed</p>
                            <p class="text--disabled">
                                Tap into the power of social media by sharing
                                your Gig, and
                                <a class="mr-1" href="#">get expert help</a>to
                                grow your impact.
                            </p>
                            <v-btn class="" outlined color="primary"
                                >Share Your Gigs</v-btn
                            >
                        </v-col>
                        <v-col
                            cols="12"
                            lg="4"
                            class="text-18-left text-center"
                        >
                            <v-icon class="info--text mb-2"
                                >mdi-book-open-variant</v-icon
                            >
                            <p class="heading-label ma-0 ">
                                GET MORE SKILLS & EXPOSURE
                            </p>
                            <p class="text--disabled">
                                Hone your skills and expand your knowledge with
                                online courses. You’ll be able to offer more .
                            </p>
                            <v-btn class="" outlined color="primary"
                                >Explore Learn</v-btn
                            >
                        </v-col>
                        <v-col
                            cols="12"
                            lg="4"
                            class="text-18-left text-center"
                        >
                            <v-icon class="warning--text mb-2"
                                >mdi-trophy</v-icon
                            >
                            <p class="heading-label ma-0 ">GET NOTICED</p>
                            <p class="text--disabled">
                                Tap into the power of social media by sharing
                                your Gig, and
                                <a class="mr-1" href="#">get expert help</a>to
                                grow your impact.
                            </p>
                            <v-btn class="" outlined color="primary"
                                >Watch Free Course</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-card-text>
            </base-card> -->

            <v-row>
                <!-- <v-col cols="12" md="6">
                    <base-card class="h-full py-5">
                        <v-card-text class="d-flex align-start ">
                            <v-icon color="primary" class="mr-2"
                                >mdi-lightbulb-on-outline</v-icon
                            >
                            <div>
                                <h5 class="font-weight-bold body-1">
                                    Tip #1: Business brings business
                                </h5>
                                <p class="ma-0">
                                    Every order counts! The more orders you get,
                                    the more successful you’ll become.
                                </p>
                            </div>
                        </v-card-text>
                    </base-card>
                </v-col>
                <v-col cols="12" md="6">
                    <base-card class="h-full py-5">
                        <v-card-text class="d-flex align-start">
                            <v-icon color="primary" class="mr-2"
                                >mdi-lightbulb-on-outline</v-icon
                            >
                            <div>
                                <h5 class="font-weight-bold body-1">
                                    Tip #2: Keep the cycle going & win buyers
                                </h5>
                                <p class="ma-0">
                                    Get an order, provide quality service, and
                                    get great reviews. You’ll get more traffic,
                                    and more orders.
                                </p>
                            </div>
                        </v-card-text>
                    </base-card>
                </v-col> -->

                <!-- <v-col cols="12">
                    <v-carousel
                        cycle
                        height="100%"
                        hide-delimiters
                        show-arrows-on-hover
                        :light="!$vuetify.theme.dark"
                    >
                        <v-carousel-item v-for="(slide, i) in slides" :key="i">
                            <v-sheet>
                                <v-row class="" align="center" justify="center">
                                    <div class="d-flex pa-4">
                                        <img
                                            class="mr-2"
                                            height="65"
                                            width="65"
                                            :src="slide.img"
                                            alt=""
                                        />
                                        <div>
                                            <div class="card-title">
                                                {{ slide.title }}
                                            </div>
                                            <p>{{ slide.paragraph }}</p>
                                            <v-btn color="primary"
                                                >learn more</v-btn
                                            >
                                        </div>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                    </v-carousel>
                </v-col> -->                
            </v-row>
        </v-col>     
        <v-dialog
            v-if="profilepreviewdialog"
            v-model="profilepreviewdialog"                                
            max-width="1000px"        
        >
            <v-card>
            <v-card-text>                    
                <profile-details :therapist_code="code" :previewMode="true" @clickclose="profilepreviewdialog=false"></profile-details>
            </v-card-text>
            <!-- <v-card-actions>
                <v-btn color="primary" block @click="dialog = false">Close Dialog</v-btn>
            </v-card-actions> -->
            </v-card>
        </v-dialog>  
        <v-snackbar
            v-model="snackbar"
            :timeout="6000"
            bottom
            tile
            color="green"
            >
            {{ $root.$i18n.locale == "gr" ? "Οι αλλαγές αποθηκεύτηκαν επιτυχώς" : "Changes Updated Successfully" }}
        </v-snackbar>          
    </v-row>
    
</template>
<style scoped>

</style>
<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'
import {api, srvApi, srvApiNoAuth, PATH, getAssetUrl} from "src/api/index";
import LocationEditor from "@/components/home/LocationEditor";
import TherapistServicesAndExperienceFieldGroup from "@/components/home/TherapistServicesAndExperienceFieldGroup";
import TherapistBookingFieldGroup from "@/components/home/TherapistBookingFieldGroup";
import TherapistLocationSelector from "@/components/home/TherapistLocationSelector";
import BusinessOwnerBusinessInfoFieldGroup from "@/components/home/BusinessOwnerBusinessInfoFieldGroup";
import FileUploaderPhoto from "@/components/core/FileUploaderPhoto";
import WeekdayAvailabilityEditor from "@/components/core/WeekdayAvailabilityEditor";
import DateAvailabilityEditor from "@/components/core/DateAvailabilityEditor";
import AreaCoverageEditor from "@/components/home/AreaCoverageEditor";
import ServiceCatalogDatatableEditor from "@/components/core/ServiceCatalogDatatableEditor";
import ProfileDetails from '@/views/mng/home/profiledetails';


import {VueperSlides, VueperSlide} from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

export default {
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Profile Edit'
    },
    components: {
        LocationEditor,
        TherapistServicesAndExperienceFieldGroup,
        TherapistBookingFieldGroup,
        TherapistLocationSelector,
        BusinessOwnerBusinessInfoFieldGroup,
        VueperSlides, 
        VueperSlide,
        FileUploaderPhoto,
        WeekdayAvailabilityEditor,
        DateAvailabilityEditor,
        AreaCoverageEditor,
        ServiceCatalogDatatableEditor,
        'profile-details': ProfileDetails
    },
    data() {
        return {

            // cal_offset: 0,
            // cal_range: 7,

            selectedCategory: null,
            //mainrole: 'Customer', //Therapist, Business Owner, Customer
            profilepreviewdialog: false,
            selectedTherapist: null,

            snackbar: false,
            //snackbarMessage: "GoogleLibrary_Endpoint ERROR: (REQ-xd234) Description: Geolocation Module Request Limit Reached, Too many requests",
            snackbarMessage: "Οι αλλαγές σας αποθηκεύτηκαν επιτυχώς.",

            initialState: null,


            help_professionalquestion: true,
            help_visibility: 'Hidden',
            
            loading_saveAll: false,
            loading_setDefaultLocation: false,


            //CUSTOMER INFO
            completedsessioncount: null,

            //CUSTOMER FIELDS
            code: "",
            email: "", //"apapadatosdev@gmail.com",
            mobile: "", //"6995322916",
            firstname: "", //this.$store.state.loggedInUser.user.firstname, 
            lastname: "", //this.$store.state.loggedInUser.user.lastname,

            language: "en",
            gender: "", //"Male",
            age: "", //"35-40",            

            profilepic_fileid: "",

            //customerLocations: [this.createContactObject()],            
            customerLocations: [],       
            
            custvalstate: null,



            //THERAPIST AVAILABILITY
            short_description: "",
            long_description: "",
            extra_images: [],
            availability_monday: [],
            availability_tuesday: [],
            availability_wednesday: [],
            availability_thursday: [],
            availability_friday: [],
            availability_saturday: [],
            availability_sunday: [],
            availability_customdate: [],
            myservices: [],


            //THERAPIST INFO
            previousMonthTotalEarnings: null,

            //THERAPIST FIELDS
            therapist_location_fieldgroup: {
                selectedCities: ["ΑΘΗΝΑ"],
                selectedPrefectures: ["ΑΤΤΙΚΗ"],
            },
            therapist_svc_fieldgroup: {
                experience_years: 4,
                experience_months: 6,
                expertise: ['physio', 'massage'],                
                caninvoice: 'yes',
                vatnumber: '15678954588'
            },
            therapist_booking_fieldgroup: {
                bookingflow: 'immediate',
                cancelationflow: '50percent',
            },

            available_for_bookings: false,

            //BUSINESS OWNER
            businessowner_businessinfo_fieldgroup: {
                businessCommercialName: "Olive Therapy",
                operatingSinceYear: 2018,
                ownerfullname: "Ελένη Κωσταντοπούλου",
                vatnumber: "4568789544",
                noofemployees: "1-5",
                officialservicesdescription: "Υπηρεσίες Αισθητικής & Spa",
            },

            

            //STATS
            completed_sessions_count: 0,
            total_earnings: 0,
            profile_visitors_count: 0,

            locations: [],//[this.createContactObject()],                        

            //STATIC REF LISTS
            languagesOptions: [{text: 'English', value: 'en'},{text: 'Ελληνικά', value: 'gr'}],
            genderOptions: [{text: "Male", value: "male"}, {text: "Female", value: "female"}, {text: "Other", value: "other"}],
            ageOptions: ["<20", "20-25", "25-30", "30-35", "35-40", "40-45", "45-50", "50-55", "55-60", "60-65", "65-70", "70-75", ">75"],
            //occupations: [], //['Cosmetology','Physiotherapy','Massage', 'Reflexology'],            
            //DYNAMIC REF LISTS
            prefectures: [],
            cities: [],

            // slides: [
            //     {
            //         img: require('@/assets/images/vue.png'),
            //         title: 'Take Course On Vue Today',
            //         paragraph:
            //             'Put your professional career in full force with courses on Learn from React. Gain new skills, earn a badge on your profile, and keep those sales rolling in.'
            //     },
            //     {
            //         img: require('@/assets/images/Sass_icon.png'),
            //         title: 'Communicate With Your Customers',
            //         paragraph:
            //             'Put your professional career in full force with courses on Learn from React. Gain new skills, earn a badge on your profile, and keep those sales rolling in.'
            //     },
            //     {
            //         img: require('@/assets/images/Html_Icon.png'),
            //         title: 'Take Course On HTML Today',
            //         paragraph:
            //             'Put your professional career in full force with courses on Learn from React. Gain new skills, earn a badge on your profile, and keep those sales rolling in.'
            //     }
            // ],





            cards: [
            {
                title: 'Beats Headphone',
                details: 'Enjoy Premium Sound',
                price: '39',
                img: require('@/assets/images/products/headphone-1.jpg')
            },
            {
                title: 'Iphone 11pro',
                details: '6G , 4k FPS',
                price: '119',
                img: require('@/assets/images/products/watch-1.jpg')
            },
            {
                title: 'Iphone 11pro',
                details: '6G , 4k FPS',
                price: '119',
                img: require('@/assets/images/products/watch-1.jpg')
            },
            {
                title: 'Beats Headphone',
                details: 'Enjoy Premium Sound',
                price: '39',
                img: require('@/assets/images/products/headphone-1.jpg')
            },
            {
                title: 'Iphone 11pro',
                details: '6G , 4k FPS',
                price: '119',
                img: require('@/assets/images/products/watch-1.jpg')
            }
        ],
        slides: [
            {image: require('@/assets/images/products/headphone-1.jpg')},
            {image: require('@/assets/images/products/headphone-2.jpg')},
            {image: require('@/assets/images/products/iphone-1.jpg')},
            {image: require('@/assets/images/products/speaker-1.jpg')},
            {image: require('@/assets/images/products/watch-2.jpg')},
            {image: require('@/assets/images/products/headphone-2.jpg')},
            {image: require('@/assets/images/products/headphone-1.jpg')},
            {image: require('@/assets/images/products/headphone-2.jpg')}
        ]
        }
    },
    // watch: {
    //     custvalstate: {
    //         handler(val)
    //     }
    // },
    computed: {
        ...mapGetters(['loggedInUser', 'refSystemServiceGroups', 'refSystemServices']),
        mainrole() {
            return this.capitalizeString(this.loggedInUser.user.role);
        },
        firstnameValState() {
            return this.custvalstate?.firstName?.msg;
        },
        lastnameValState() {
            return this.custvalstate?.lastName?.msg;
        },
        genderValState() {
            return this.custvalstate?.gender?.msg;
        },
        ageValState() {
            return this.custvalstate?.age?.msg;
        },
        locationsValState() {
            return this.custvalstate?.locations;
        },
        profilePicThumbnailUrl() {
            if(this.profilepic_fileid) {
                return getAssetUrl(this.profilepic_fileid, true);
            }
            else {
                return "https://placehold.co/200x200?text=Upload+Photo";
            }
        },
        occupations() {                        
            let localePostfix = this.$root.$i18n.locale;
            return this.refSystemServiceGroups?.map(stg => ({text: stg["title_" + localePostfix], value: stg.code}) ) || [];
        },
        dropdown_service_types(){
            let localePostfix = this.$root.$i18n.locale;
            return this.refSystemServices?.map(stg => ({text: "["+ stg["group_title_" + localePostfix] + "] > " + stg["title_" + localePostfix], value: stg.code}) ) || [];            
        },
        columnSelector_Categories() {
            let localePostfix = this.$root.$i18n.locale;     
            let rslt = this.refSystemServiceGroups.map(
                g=>({
                    value: g.code,
                    text: g["title_"+localePostfix]
                })
            );            
            return rslt;
        },
        columnSelector_Services() {
            let localePostfix = this.$root.$i18n.locale;     
            let rslt = this.refSystemServices.map(
                g=>({
                    value: g.code,
                    text: g["title_"+localePostfix]
                })
            );
            if(this.selectedCategory) {
                rslt = this.refSystemServices
                        .filter(g=>g.service_type_group == this.selectedCategory)
                        .map(
                            g=>({
                                value: g.code,
                                text: g["title_"+localePostfix]
                            })
                        )
            }
            return rslt;
        }
    },
    methods: {
        ...mapMutations(['setProfilePic']),
        addServiceFilter(serviceCode) {
            this.$refs.serviceCatalog.addSpecificServiceType(serviceCode);
        },
        async therapServiceCreate(serviceData) {
            const rsp = await srvApi.post(PATH.PROFILE_MYSERVICES, serviceData);
            this.myservices.push(rsp.data);
        },
        async therapServiceUpdate(serviceData) {
            const rsp = await srvApi.patch(PATH.PROFILE_MYSERVICES, serviceData);
            Object.assign(this.myservices.find(s=>s.code==serviceData.code), rsp.data);
        },
        async therapServiceDelete(serviceData) {
            const rsp = await srvApi.delete(PATH.PROFILE_MYSERVICES+"/"+serviceData.code);
            const indexToDelete = this.myservices.findIndex(s=>s.code == serviceData.code);
            if(indexToDelete>=0) {
                this.myservices.splice(indexToDelete, 1);
            }
        },

        async previewMyProfile(){
            // const rspTherapist = await srvApi.get(PATH.BOOKING_MYPROFILE + "/?offset=" + this.cal_offset + "&range=" + this.cal_range );
            // this.selectedTherapist = [rspTherapist.data].map(t=>({
            //     img: getAssetUrl(t.profile_photo),
            //     title: t.firstname + " " + t.lastname,
            //     subTitle: t.short_description,
            //     progressLinearColor: 'primary',
            //     rating: t.rating,
            //     ratingCount: t.rating_count,
            //     details: {...t}
            // }))[0];
            this.profilepreviewdialog=true;
        },  

        async handleFiles(files) {
            let formData = new FormData();
            formData.append("file", files[0]); 
            try {
                const rsp = await srvApi.postfile(
                    PATH.UPLOADS_UPLOADPROFILEPIC,
                    formData
                    /*, this.id.toString(), index, this.assets[index].id, 
                    (event) => {
                        this.assets[index].uploadProgress = Math.round((100 * event.loaded) / event.total);
                    }*/
                )
                this.profilepic_fileid = rsp?.data?.fileid;
                this.setProfilePic(getAssetUrl(this.profilepic_fileid));
            } catch (error) {
                console.log("Upload Error: " + error?.response?.data?.message);   
            }            
        },
        capitalizeString(srcTxt) {
            if(srcTxt.length > 0) {
                return srcTxt.charAt(0).toUpperCase() + srcTxt.slice(1)
            }
            return srcTxt;
        },  
        createContactObject() {            
            return {                
                primary_location: this.customerLocations.length==0 ? true : false,
                position: null,
                title: "",
                prefecture: "", //"Περιφερειακή ενότητα Βορείου Τομέα Αθηνών",
                municipality: "", //"Νέα Ιωνία",
                street_address: "", //""Ομήρου 13",
                postal_code: "", //"142 33", //"12356",
                // phoneno: "", //"2102 795835",
                // email: "", //"alldayhome42@gmail.com",
                latcoord: null, //38.036843176315436, //37.9795244,    38.036843176315436, 23.758162196827396
                lngcoord: null, //23.758162196827396, //23.7359515
                extra_info: null,
                valstate: null
            }
        },
        generateUpdateDto() {
            const rslt = {                
                firstName: this.firstname,
                lastName: this.lastname,
                language: this.language,
                gender: this.gender,
                age: this.age,
                locations: this.customerLocations,
                availability_monday: this.availability_monday,
                availability_tuesday: this.availability_tuesday,
                availability_wednesday: this.availability_wednesday,
                availability_thursday: this.availability_thursday,
                availability_friday: this.availability_friday,
                availability_saturday: this.availability_saturday,
                availability_sunday: this.availability_sunday,
                availability_customdate: this.availability_customdate

                // .map(loc=>({
                //     title: loc.title
                // }))
            }
            return rslt;
        },
        async saveAll() {
            this.loading_saveAll = true;
            try {
                let that = this;
                //gather data
                const updateData = this.generateUpdateDto();
                const rspSave = await srvApi.patch(PATH.PROFILE_MY, updateData);        
                
                if(rspSave?.data?.valstate?.valid == false) {
                    this.custvalstate = rspSave.data.valstate;   
                    for(let i=0; i<this.customerLocations.length; i++) {
                        if(this.custvalstate.hasOwnProperty("locations") && Array.isArray(this.custvalstate.locations))
                        {
                            this.customerLocations[i].valstate = this.custvalstate.locations[i];                            
                        }
                    }                              
                }
                else {
                    this.custvalstate = null;
                }   
                this.setInitialState();
                this.snackbar=true;
            } catch (error) {
               console.log(JSON.stringify(error.response.data, null, 2));
            }
            finally {
                this.loading_saveAll = false;
            }   
            /*    
            if(this.mainrole=="Therapist") {
                this.snackbarMessage = "GoogleLibrary_Endpoint ERROR: (REQ-xd234) Description: Geolocation Module Request Limit Reached, Too many requests";
                this.snackbar = true;
            }
                */
        },
        setDefaultLocation(locationIndex) {    
            
            this.customerLocations.forEach((loc,idx)=> {    
                let that = this;
                this.loading_setDefaultLocation = true;            
                if(idx==locationIndex) {
                    setTimeout(() => { 
                        loc.primary_location = true;
                        that.loading_setDefaultLocation = false;
                    }, 800);                    
                }
                else {
                    loc.primary_location = false;
                }
            });            
        },


        // UNSAVED CHANGES METHODS START
        setInitialState() {
            this.initialState = JSON.stringify(this.generateUpdateDto());
        },
        hasUnsavedChanges() {
            const latestState = JSON.stringify(this.generateUpdateDto());
            const previewInitialState = JSON.stringify(this.initialState);
            return latestState !== this.initialState;
        },
        confirmLeave() {
            return window.confirm('Do you really want to leave? you have unsaved changes!')
        },
        confirmStayInDirtyForm() {
            return this.hasUnsavedChanges() && !this.confirmLeave()
        },
        beforeWindowUnload(e) {
            if (this.hasUnsavedChanges()) {
                // Cancel the event
                e.preventDefault();
                // Chrome requires returnValue to be set
                e.returnValue = '';
            }   
        },
        // UNSAVED CHANGES METHODS END
    },
    async mounted() {
        const rspProfile = await srvApi.get(PATH.PROFILE_MY);
        this.code = rspProfile.data.code;
        this.email = rspProfile.data.username;
        this.mobile = rspProfile.data.mobile;
        this.firstname = rspProfile.data.firstName;
        this.lastname = rspProfile.data.lastName;
        this.language = rspProfile.data.language; 
        this.gender = rspProfile.data.gender;
        this.age = rspProfile.data.age;

        this.customerLocations.length=0;
        this.customerLocations.push(...rspProfile.data.locations.map((loc) => { return {...loc, valstate: null}}));

        this.completed_sessions_count = rspProfile.data.statistics?.completed_sessions_count;
        this.total_earnings = rspProfile.data.statistics?.total_earnings;
        this.profile_visitors_count = rspProfile.data.statistics?.profile_visitors_count;   
        
        this.profilepic_fileid = rspProfile.data.profile_photo;

        if(rspProfile.data.role == "therapist") {

            
            const rspTherapist = await srvApi.get(PATH.PROFILE_MYTHERAPIST);
            this.short_description = rspTherapist.data.short_description;
            this.long_description = rspTherapist.data.long_description;
            this.available_for_bookings = rspTherapist.data.available_for_bookings.toString();                      
            this.extra_images.push(...rspTherapist.data.extra_images.map(x=>getAssetUrl(x)));            
            this.availability_monday.push(...(rspTherapist.data.availability_monday || []));            
            this.availability_tuesday.push(...(rspTherapist.data.availability_tuesday || []));
            this.availability_wednesday.push(...(rspTherapist.data.availability_wednesday || []));
            this.availability_thursday.push(...(rspTherapist.data.availability_thursday || []));
            this.availability_friday.push(...(rspTherapist.data.availability_friday || []));
            this.availability_saturday.push(...(rspTherapist.data.availability_saturday || []));
            this.availability_sunday.push(...(rspTherapist.data.availability_sunday || []));
            this.availability_customdate.push(...(rspTherapist.data.availability_customdate || []));   
            this.myservices.push(...(rspTherapist.data.myservices || []));                                 
            //this.$forceUpdate();
            //console.log(JSON.stringify(rspTherapist.data, null,2));
        }
        
        this.setInitialState();

        //console.log(JSON.stringify(rspProfile.data,  null, 2));

        // // this.firstname = "test1";
        // // this.lastname = "test2";
        // this.firstname = this.loggedInUser.user.firstName;
        // this.lastname = this.loggedInUser.user.lastName;

        //const rsp = await api.hud.myhomepageinfo();        
        // if(JSON.parse(localStorage.getItem("userInfo")).user.role !== "customer") {
        //     this.openVerticalSaasSidebarDrawer();
        // }
        // this.prefectures.push(...rsp.data.prefectures);
        // this.cities.push(...rsp.data.areas);

        //this.occupations.push(this.refServiceTypeGroups.map(stg => new {text: stg.title_gr, value: stg.code}));
    },

    // UNSAVED CHANGES EVENTS START

    beforeRouteLeave (to, from, next) {        
        // If the form is dirty and the user did not confirm leave,
        // prevent losing unsaved changes by canceling navigation
        if (this.confirmStayInDirtyForm()){
            next(false)
        } else {
            // Navigate to next view
            next()
        }
    },

    created() {
        window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy() {
        window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },

    // UNSAVED CHANGES EVENTS END
}
</script>
